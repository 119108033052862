@import "../../../SCSS/Mixins.scss";

.inputFields {
  position: relative;
  width: 100%;
  // z-index: 9;
  p {
    @include fontSize(14px);
    line-height: 16px;
  }

  .MuiInputBase-root {
    background: $white;
    border-radius: 6px;
    border: 1px solid #b5b5b5;
    height: 38px !important;
    &:before,
    &:after {
      display: none;
    }
    &.Mui-focused {
      border-color: $primary;
    }
    .MuiInputBase-input {
      @include fontSize(16px);
      font-family: "JioType";
      font-weight: 500;
      font-style: normal;
      padding: 4px 12px;
      background: transparent;
    }
    .MuiSelect-select {
      em {
        opacity: 0.5;
      }
    }
  }

  fieldset {
    border: none !important;
  }

  .error {
    background: url("../../../../public/images/red_cross.svg") no-repeat left
      center/16px;
    color: #660014;
    @include fontSize(12px);
    padding-left: 24px;
    font-weight: 500;
    text-align: left;
    line-height: 16px;
    display: block;
    margin-top: 4px;
    position: relative;
    z-index: 0;
    // @include position(absolute, $left: 0px, $bottom: -20px);
  }
}

.mobileNumber {
  position: relative;
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-left: 42px !important;
    }
  }
}
